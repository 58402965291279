<template>
	<!-- 治疗需求 -->
	<div class="zhiliaoxuqiu">
		<div align="left" class="patientLeft">
		<!-- 	<img src="../assets/zhiliaoxuqiu.png" width="400" height="40" /> -->
			<br />
			<br />
			<br />
			<!-- <img src="../assets/签字.png" style="padding-right: 11.875rem;"/> -->
		</div>
		<div class="right">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item style="text-align: left;">
					<span class="req">*</span><span class="reqoption">1.基托颜色(多选)</span>
					<el-tree  :data="jituo" ref="jituo" show-checkbox node-key="id" :props="jituoProps">
					</el-tree>
				</el-form-item>
				<el-form-item style="text-align: left;">
					<span class="req">*</span><span class="reqoption">2.加工产品编号(多选)</span>
					<!-- 正畸 -->
					<el-tree :data="zhengji" ref="zhengji" show-checkbox node-key="id" :props="zhengjiProps">
					</el-tree>
					<!-- 功能矫治器类 -->
					<el-tree class="gn" :data="gongneng" ref="gongneng" show-checkbox node-key="id" :props="gongnengProps">
					</el-tree>
					<!-- 螺旋扩大器类 -->
					<el-tree :data="luoxuan" ref="luoxuan" show-checkbox node-key="id" :props="luoxuanProps">
					</el-tree>
					<!-- 美白/阻鼾器/夜磨牙垫类 -->
					<el-tree class="gn" :data="yadian" ref="yadian" show-checkbox node-key="id" :props="yadianProps">
					</el-tree>
					<!-- 其他类别 -->
					<el-tree :data="qita" ref="qita" show-checkbox node-key="id" :props="qitaProps">
					</el-tree>
					<!--  FACE正畸专业颌板 -->
					<el-tree class="gn" :data="heban" ref="heban" show-checkbox node-key="id" :props="hebanProps">
					</el-tree>
				</el-form-item>
				<el-form-item style="text-align: left;">
					<span class="req">*</span><span class="reqoption">3.治疗需求</span>
					<el-input type="textarea" v-model="ruleForm.planDoctor"></el-input>
				</el-form-item>
				<!-- 预留部分显示订单不通过怎么办 -->
				<el-form-item v-if="isShow">
					
				</el-form-item>
				<!-- 画图说明矫治需求 -->
				<el-form-item style="text-align: left;">
					<div><span class="req">*</span><span class="reqoption">4.画图说明矫治需求</span><br/></div>
					<!-- 下载图片 -->
					<div class="loadimg">
						<a style="font-weight: bold;text-decoration: underline;font-size: 0.875rem;" href="./yachi.jpeg" download="yachi.jpg">下载图片</a><br/>
						<img src="../assets/yachi.png" width="200" height="200" />
					</div>
					<!-- 上传图片 -->
					<div class="uploadimg" style="margin-left: 0.625rem;">
						<a>上传图片(有效格式：.jpg、.jpeg)</a>
						<input @change="ll"  type="file" name="file" id="file" hidden="hidden" accept="image/jpg,image/jpeg"/>
						<div class="imgcon1" @click="uploadImgNeeds">
							<img id="i" width="100%" height="100%" />
						</div>
					</div>

				</el-form-item>
				<!-- <el-form-item style="text-align: left;background-color: pink;">
					 上传图片
					<div class="uploadimg">
						<a>上传图片(有效格式：.jpg、.jpeg)</a>
						<input @change="ll"  type="file" name="file" id="file" hidden="hidden"/>
						<div class="imgcon1" @click="uploadImgNeeds">
							<img id="i" width="100%" height="100%" />
						</div>
						<button class="btnUploadImg" type="button" @click="ll">上传图片</button>
					</div>
				</el-form-item> -->
				<el-form-item>
					<button class="btnNextThree" type="button" @click="goOrderOne">返回</button>
					<button class="btnNextThree" type="button" @click="zhengJiArray">下一步</button>
				</el-form-item>
			</el-form>
		</div>
		
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				file:'',
				isShow:false,
				ruleForm: {
					planDoctor: '',
					oid:'0'
				},
				rules: {
				
				},
				jituo: [
					{
						id:0,
						label:"基托颜色",
						children:[
							{
									id: '不需要基托',
									label: '不需要基托'
								}, {
									id: 'Clear',
									label: '无色(Clear)'
								}, {
									id: 'Red',
									label: '红色(Red)'
								}, {
									id: 'Yellow',
									label: '黄色(Yellow)'
								}, {
									id: 'Blue',
									label: '蓝色(Blue)'
								},
								{
									id: 'Green',
									label: '绿色(Green)'
								},
								{
									id: 'Purple',
									label: '紫色(Purple)'
								},
								{
									id: 'Neon-Yellow',
									label: '霓虹黄(Neon-Yellow)'
								},
								{
									id: '我不在意颜色可任意',
									label: '我不在意颜色可任意'
								}
						]
					}
				],
				jituoProps:{
					 children: 'children',
					          label: 'label'
				},
				zhengji: [{
					id: 1,
					label: '正畸保持器类',
					children: [{
							id: "A-0001",
							label: '比格保持器 A-0001'
						},
						{
							id: "A-0002",
							label: '霍利式保持器 A-0002'
						},
						{
							id: "A-0003",
							label: '霍利式保持器(焊接式) A-0003'
						},
						{
							id: "A-0004",
							label: '压膜保持器 A-0004'
						},
						{
							id: "A-0005",
							label: '南丝腭弓 A-0005'
						},
						{
							id: "A-0006",
							label: '舌弓 A-0006'
						},
						{
							id: "A-0007",
							label: '横腭杆 A-0007'
						},
						{
							id: "A-0008",
							label: '间隙保持器 A-0008'
						},
						{
							id: "A-0009",
							label: '舌侧固定保持器(麻花丝) A-0009'
						},
						{
							id: "A-0010",
							label: '顺其保持器OTP A-0010'
						}
					]
				}],
				zhengjiProps:{
					 children: 'children',
					          label: 'label'
				},
				gongneng:[
					{
						id: 2,
						label: '功能矫治器类',
						children: [{
								id: "B-0001",
								label: '法兰克功能矫治器I B-0001'
							},
							{
								id: "B-0002",
								label: '法兰克功能矫治器II B-0002'
							},
							{
								id: "B-0003",
								label: '法兰克功能矫治器II B-0003'
							},
							{
								id: "B-0004",
								label: '肌激动器 B-0004'
							},
							{
								id: "B-0005",
								label: '改良型肌激动器 B-0005'
							},
							{
								id: "B-0006",
								label: '改良型肌激动器 B-0006'
							},
							{
								id: "B-0007",
								label: '平面导板 B-0007'
							},
							{
								id: "B-0008",
								label: '平面导板(固定式) B-0008'
							},
							{
								id: "B-0009",
								label: '斜面导板 B-0009'
							},
							{
								id: "B-0010",
								label: '前庭盾 B-0010'
							},
							{
								id: "B-0011",
								label: '弹簧矫正器 B-0011'
							},
							{
								id: "B-0012",
								label: '习惯破除器 B-0012'
							},
							{
								id: "B-0013",
								label: '舌档 B-0013'
							},
							{
								id: "B-0014",
								label: '舌刺(固定) B-0014'
							},
							{
								id: "B-0015",
								label: '膜片式正位器PR B-0015'
							},
							{
								id: "B-0016",
								label: '舌体抬高器TE B-0016'
							},
						]
					}
				],
				gongnengProps:{
					 children: 'children',
					 label: 'label'
				},
				luoxuan:[
					{
						id: 3,
						label: '螺旋扩大器类',
						children: [{
								id: "C-0001",
								label: '上颌快速扩弓(螺旋式) C-0001'
							}, {
								id: "C-0002",
								label: '上颌快速扩弓(螺旋式)带面弓勾 C-0002'
							},
							{
								id: "C-0003",
								label: '上颌快速扩弓(记忆型8mm) C-0003'
							},
							{
								id: "C-0004",
								label: '上颌快速扩弓(记忆型10mm) C-0004'
							},
							{
								id: "C-0005",
								label: '四眼簧扩弓器(舌侧焊接) C-0005'
							},
							{
								id: "C-0006",
								label: '四眼簧扩弓器(舌侧扣) C-0006'
							},
							{
								id: "C-0007",
								label: '骨性局部螺旋器 C-0007'
							},
							{
								id: "C-0008",
								label: '通用型扩弓器 C-0008'
							},
							{
								id: "C-0009",
								label: '通用型扩弓器11mm C-0009'
							},
							{
								id: "C-0010",
								label: '横向记忆扩弓器 C-0010'
							},
							{
								id: "C-0011",
								label: '三维扩弓器(螺旋式) C-0011'
							},
							{
								id: "C-0012",
								label: '三维扩弓器(记忆式) C-0012'
							},
							{
								id: "C-0013",
								label: '扇形扩弓器(一体式) C-0013'
							},
							{
								id: "C-0014",
								label: '扇形扩弓器(固定式) C-0014'
							},
							{
								id: "C-0015",
								label: '扇形扩弓器(分离式) C-0015'
							},
							{
								id: "C-0016",
								label: '下颌弓型扩弓器 C-0016'
							},
							{
								id: "C-0017",
								label: '舌侧舒适扩大螺旋器(8mm) C-0017'
							},
							{
								id: "C-0018",
								label: '舌侧舒适扩大螺旋器(12mm) C-0018'
							},
							{
								id: "C-0019",
								label: '舌侧常规扩大螺旋器(8mm) C-0019'
							},
							{
								id: "C-0020",
								label: '磨牙7颊侧直立矫正器 C-0020'
							},
							{
								id: "C-0021",
								label: '双导面功能矫治器TwinBlock(不可调) C-0021'
							},
							{
								id: "C-0022",
								label: '双导面功能矫治器TwinBlock(螺旋式可调) C-0022'
							},
							{
								id: "C-0023",
								label: '磨牙远移基托式(青蛙装置I) C-0023'
							},
							{
								id: "C-0024",
								label: '磨牙远移支抗钉式(青蛙装置II) C-0024'
							},
							{
								id: "C-0025",
								label: '钟摆矫治器(TMA丝) C-0025'
							},
							{
								id: "C-0026",
								label: 'SanderII导下颌向前(非记忆型) C-0026'
							},
							{
								id: "C-0027",
								label: 'SanderII导下颌向前(记忆型) C-0027'
							},
							{
								id: "C-0028",
								label: 'SanderII导下颌向后 C-0028'
							},
							{
								id: "C-0029",
								label: 'II类功能矫治器(基托式) C-0029'
							},
							{
								id: "C-0030",
								label: 'Herbst咬合前移器(钢铸件) C-0030'
							},
							{
								id: "C-0031",
								label: 'Williams咬合前移器颌间可调(带环式) C-0031'
							},
							{
								id: "C-0032",
								label: 'Williams咬合前移器颌间可调(钢铸件) C-0032'
							},
							{
								id: "C-0033",
								label: 'FMA下颌功能推进器(钢铸件) C-0033'
							},
							{
								id: "C-0034",
								label: '舌簧合垫 C-0034'
							},
							{
								id: "C-0035",
								label: '顺其II C-0035'
							},
							{
								id: "C-0036",
								label: '顺其III C-0036'
							},
						]
					}
				],
				luoxuanProps:{
					 children: 'children',
					 label: 'label'
				},
				yadian:[
					{
						id: "4",
						label: "美白/阻鼾器/夜磨牙垫",
						children: [{
								id: "D-0001",
								label: '美白托盘(膜片) D-0001'
							},
							{
								id: "D-0002",
								label: '阻鼾器(高端，锁扣可调式)停产因没有配件 D-0002'
							},
							{
								id: "D-0003",
								label: '阻鼾器(停产因没有配件) D-0003'
							},
							{
								id: "D-0004",
								label: '夜磨牙合垫(软膜片) D-0004'
							},
							{
								id: "D-0005",
								label: '夜磨牙合垫(硬膜片) D-0005'
							},
							{
								id: "D-0006",
								label: '夜磨牙合垫(软/硬双面膜片) D-0006'
							}
						]
					}
				],
				yadianProps:{
					 children: 'children',
					 label: 'label'
				},
				qita:[
					{
						id: "5",
						label: '其他类别',
						children: [{
								id: 'E-0001',
								label: '正畸寄存模型 E-0001'
							},
							{
								id: 'F-0001',
								label: '唇侧间接粘接 F-0001'
							},
							{
								id: 'F-0002',
								label: '唇侧间接粘接 F-0002'
							}
						]
					}
				],
				qitaProps:{
					 children: 'children',
					 label: 'label'
				},
				heban:[
					{
						id: "6",
						label: 'FACE正畸专业颌板',
						children: [{
								id: 'G-0001',
								label: '颌板(膜片) G-0001'
							},
							{
								id: 'F-0002',
								label: '颌板(树脂) G-0002'
							}
						]
					}
				],
				hebanProps:{
					 children: 'children',
					 label: 'label'
				},
				
			}
		},
		methods: {
			//单击下一步调用的方法
			zhengJiArray(){
				let jituo=this.$refs.jituo.getCheckedKeys();
				let zhengji=this.$refs.zhengji.getCheckedKeys();
				let gongneng=this.$refs.gongneng.getCheckedKeys();
				let luoxuan=this.$refs.luoxuan.getCheckedKeys();
				let yadian=this.$refs.yadian.getCheckedKeys();
				let qita=this.$refs.qita.getCheckedKeys();
				let heban=this.$refs.heban.getCheckedKeys();
				// console.log(zhengji);
				// console.log(gongneng);
				// console.log(luoxuan);
				// console.log(yadian);
				// console.log(qita);
				// console.log(heban);
				// console.log(jituo);
				//以上七个必须至少选中一个
				if(jituo.length==0){
					this.$message.warning("请选择基托颜色");
				}else if(zhengji.length==0&&gongneng.length==0&&luoxuan.length==0&&yadian.length==0&&qita.length==0&&heban.length==0){
					this.$message.warning("请选择加工产品");
				}else if(this.ruleForm.planDoctor==""||this.ruleForm.planDoctor==null){
					this.$message.warning("请填写治疗需求");
				}else{
					var a={
						jituo:jituo,
						zhengji:zhengji,
						gongneng:gongneng,
						luoxuan:luoxuan,
						yadian:yadian,
						qita:qita,
						heban:heban,
						oid:this.ruleForm.oid,
						planDoctor:this.ruleForm.planDoctor
					}
					console.log(JSON.stringify(a));
					this.$axios.post("client/order/addPlan",JSON.stringify(a),{
						headers:{
							"Content-type":"application/json"
						}
					}).then(res=>{
						this.$router.push("orderthree");
					}).catch(err=>{
						console.log(err);
					});
				}
				},
				
			//单击div弹出选择文件的dialog
			uploadImgNeeds(){
				document.getElementById("file").click();
				
			},
			//进行图片上传
			ll(){
				let formData = new FormData();
				formData.append('oid', this.ruleForm.oid);
				formData.append('file', document.getElementById("file").files[0]);
				console.log(document.getElementById("file").files[0]);
				let config = { headers: { "Content-Type": "multipart/form-data" }};
				this.$axios.post('client/order/addPlanPic', formData,config)
				    .then(res=>{
						console.log(res);
						document.getElementById("i").setAttribute("src",res.data.data.picUrl);
					}).catch(err=>{
						console.log(err);
					});
			},
			//返回
			goOrderOne(){
				this.$router.push("/addorder/orderone")
			}
			
		},
		mounted: function() {
			this.$store.commit('setStepIndex', 1);
			//设置默认地址
			sessionStorage.setItem("location","ordertwo")
			//获取oid
			let oid=sessionStorage.getItem("oid");
			if(oid==null){
				this.$alert("请填写患者信息","提示");
				this.$router.push("/addorder/orderone");
			}else{
				this.ruleForm.oid=oid;
			}
			
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
			console.log(oid);
			if(oid!="undefined"&&oid!=''&&oid!=null){
				this.ruleForm.oid=oid;
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					console.log(res);
					let orderInfo=res.data.data;
					let jituo=res.data.data.plan.jituo;
					let zhengji=res.data.data.plan.zhengji;
					let gongneng=res.data.data.plan.gongneng;
					let luoxuan=res.data.data.plan.luoxuan;
					let yadian=res.data.data.plan.yadian;
					let qita=res.data.data.plan.qita;
					let heban=res.data.data.plan.heban;
					console.log("基托长度:"+jituo.length);
					console.log("颌板长度:"+heban.length);
					console.log(res.data.data.plan);
					if(jituo[0]!=''){
						this.$refs.jituo.setCheckedKeys(jituo);
					}
					if(zhengji[0]!=''){
						this.$refs.zhengji.setCheckedKeys(zhengji);
					}
					if(gongneng[0]!=''){
						this.$refs.gongneng.setCheckedKeys(gongneng);
					}
					if(luoxuan[0]!=''){
						this.$refs.luoxuan.setCheckedKeys(luoxuan);
					}
					if(yadian[0]!=''){
						this.$refs.yadian.setCheckedKeys(yadian);
					}
					if(qita[0]!=''){
						this.$refs.qita.setCheckedKeys(qita);
					}
					if(heban[0]!=''){
						this.$refs.heban.setCheckedKeys(heban);
					}
					//矫治需求回显
					if(res.data.data.plan.planDoctor!=null){
						this.ruleForm.planDoctor=res.data.data.plan.planDoctor;
					}
					//牙图片回显
					if(res.data.data.plan.planPdf!=null){
						document.getElementById("i").setAttribute("src",res.data.data.plan.planPdf);
					}
					
				}).catch(err=>{
					console.log(err);
				});
			}
			
		}
	}
</script>

<style scoped="scoped">
	.zhiliaoxuqiu {
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom,white, #9BEDEB, #28D9D5);
		display: flex;
		justify-content: center;
	}

	.patientLeft {
		padding-left: 2rem;
		margin-top: 1.25rem;
		flex: 3;
		padding-left: 6.25rem;
		text-align: right;
	}

	.right {
		overflow-y: auto;
		overflow-x: hidden;
		height: 70%;
		flex:4;
		padding-right: 3.125rem;
		padding-top: 0.625rem;
	}
	.el-tree{
		color: dimgray;
		background-color: transparent;
	}
	
	/* 图片 */
	.loadimg,.uploadimg{
		display: inline-block;
	}
	
	.imgcon1{
		width: 12rem;
		height: 12rem;
		border: 2px dashed #1196AB;
		background-image: url(../assets/icon_add@2x.png);
		background-repeat: no-repeat;
		background-position: center;
	}
	.btnNextThree{
		border: none;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		width: 50%;
		height:30px;
		border-radius: 0.625rem;
		margin-bottom: 0.625rem;
	}
	.btnUploadImg{
		border: none;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		border-radius: 0.625rem;
		height:30px;
	}
	
	/* *的颜色为红色 */
	.reqoption{
		color: #28B9D5;
		font-size: 0.875rem;
		font-weight: bold;
	}
	.req{
		color: red;
	}
	
</style>
